<template>
  <div>
    <Head title="Crear cliente" />
    <button class="bg-indigo-800 text-white rounded p-2 mr-2" @click="back()">
        <i class="fa fa-arrow-left"></i>
      </button>
    <h1 class="mb-8 text-3xl font-bold">
      <Link class="text-indigo-400 hover:text-indigo-600" href="/clients">Clientes</Link>
      <span class="text-indigo-400 font-medium">/</span> Crear
    </h1>
    <div class="max-w-3xl bg-white rounded-md shadow overflow-hidden">
      <form @submit.prevent="store">
        <div class="flex flex-wrap -mb-8 -mr-6 p-8">
          <text-input v-model="form.companyName" :error="form.errors.companyName" class="pb-8 pr-6 w-full lg:w-1/2" label="Empresa" />
          <text-input v-model="form.name" :error="form.errors.name" class="pb-8 pr-6 w-full lg:w-1/2" label="Nombre y Apellido" />
          <text-input v-model="form.email" :error="form.errors.email" class="pb-8 pr-6 w-full lg:w-1/2" label="Correo electrónico" />
          <text-input v-model="form.identification" :error="form.errors.identification" class="pb-8 pr-6 w-full lg:w-1/2" label="Alias o Cédula" />
          <text-input v-model="form.phone" :error="form.errors.phone" class="pb-8 pr-6 w-full lg:w-1/2" label="Telefono de cliente" />
          <text-input v-model="form.address" :error="form.errors.address" class="pb-8 pr-6 w-full lg:w-1/2" label="Dirección de cliente" />
          <div class="pb-8 pr-6 w-full lg:w-1/2">
            <label for="">Tipo de cliente</label>
            <select v-model="form.typeClient" class="form-input mt-2 w-full">
              <option value="">Seleccionar</option>
              <option value="freelancer">Freelancer</option>
              <option value="spot">SPOT</option>
              <option value="a">A</option>
              <option value="b">B</option>
              <option value="c">C</option>
            </select>
          </div>
        </div>
        <div class="flex items-center justify-end px-8 py-4 bg-gray-50 border-t border-gray-100">
          <loading-button :loading="form.processing" class="btn-indigo" type="submit">Crear cliente</loading-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
import Layout from '@/Shared/Layout'
import TextInput from '@/Shared/TextInput'
import SelectInput from '@/Shared/SelectInput'
import LoadingButton from '@/Shared/LoadingButton'

export default {
  components: {
    Head,
    Link,
    LoadingButton,
    SelectInput,
    TextInput,
  },
  layout: Layout,
  props: {
    organizations: Array,
  },
  remember: 'form',
  data() {
    return {
      form: this.$inertia.form({
        companyName: '',
        name: '',
        email: '',
        identification: '',
        phone: '',
        address: '',
        typeClient: ''
      }),
    }
  },
  methods: {
    back() {
      window.history.back()
    },
    store() {
      this.form.post('/clients')
    },
  },
}
</script>
