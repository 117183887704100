<template>
  <div>
    <Head title="Dashboard" />
    <h1 class="mb-8 text-3xl font-bold">Escritorio</h1>

    <div class="flex justify-between">
      <div
        v-if="role == 'financial' || role == 'admin'"
        class="bg-white rounded p-3"
      >
        <p class="text-center text-2xl font-semibold">
          Total en cuentas por cobrar
        </p>
        <p class="text-center text-3xl font-black">
          ${{ balanceTotal.toLocaleString("es") }}
        </p>
      </div>
      <div  class="bg-white rounded p-3">
        <p class="text-center text-2xl font-semibold">
          Total de productos
        </p>
        <p class="text-center text-3xl font-black">
          {{ total.toLocaleString("es") }}
        </p>
      </div>
      <div class="bg-white rounded p-3">
        <p class="text-center text-2xl font-semibold">
          Total de clientes
        </p>
        <p class="text-center text-3xl font-black">
          {{ clientTotal.toLocaleString("es") }}
        </p>
      </div>
    </div>

    <h3 class="text-xl py-2 font-bold">Ultimas ordenes</h3>
    <div
      class="bg-white rounded-md shadow overflow-x-auto"
    >
      <table class="w-full whitespace-nowrap">
        <tr class="text-left font-bold">
          <th class="pb-4 pt-6 px-6">Fecha</th>
          <th class="pb-4 pt-6 px-6">Cantidad agregada</th>
          <th class="pb-4 pt-6 px-6">Estado</th>
          <th class="pb-4 pt-6 px-6">Vendedor</th>
          <th class="pb-4 pt-6 px-6">Abonado</th>
          <th class="pb-4 pt-6 px-6">Total</th>
        </tr>
        <tr v-for="order in orders" :key="order.id" class="hover:bg-gray-100 focus-within:bg-gray-100">
          <td class="border-t">
            <Link :href="`orders/${order.id}`" class="flex items-center px-6 py-4 focus:text-indigo-500">
              {{ getDate(order.created_at) }}
            </Link>
          </td>
          <td class="border-t">
            <Link :href="`orders/${order.id}`" class="flex items-center px-6 py-4 focus:text-indigo-500">
              {{ order.client.name }}
            </Link>
          </td>
          <td class="border-t">
            <Link :href="`orders/${order.id}`" class="flex items-center px-6 py-4 focus:text-indigo-500">
              <span v-if="order.order_state == 'cancel'">ANULADA</span>
              <span v-if="order.order_state == 'request'">PENDIENTE POR PAGO</span>
              <span v-if="order.order_state == 'finish'">FINALIZADA</span>
              <span v-if="order.order_state == 'pay'">PAGADA</span>
            </Link>
          </td>
          <td class="border-t">
            <Link :href="`orders/${order.id}`" class="flex items-center px-6 py-4 focus:text-indigo-500">
              {{ order.responsible.first_name }} {{ order.responsible.last_name }}
            </Link>
          </td>
          <td class="border-t">
            <Link :href="`orders/${order.id}`" class="flex items-center px-6 py-4 focus:text-indigo-500">
              ${{ order.balance }}
            </Link>
          </td>
          <td class="border-t">
            <Link :href="`orders/${order.id}`" class="flex items-center px-6 py-4 focus:text-indigo-500">
              ${{ order.total }}
            </Link>
          </td>
        </tr>
        <tr v-if="!orders">
          <td class="px-6 py-4 border-t" colspan="4">No hay productos.</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import {Head, Link} from '@inertiajs/inertia-vue3'
import Layout from '@/Shared/Layout'

export default {
  components: {
    Link,
    Head,
  },
  layout: Layout,
  data() {
    return {
      role: this.$parent.auth.user.role,
    }
  },
  props: {
    total: Number,
    orders: Object,
    clientTotal: Number,
    balanceTotal: Number
  },
  methods: {
    getDate(date)
    {
      let dateNew = new Date(date)
      return dateNew.toLocaleString('es-VE', { hour12: false })
    }
  }
}
</script>
