<template>
  <div>
    <Head title="Clientes" />
    <div class="flex items-center justify-between mb-6">
      <button class="bg-indigo-800 text-white rounded p-2 mr-2" @click="back()">
        <i class="fa fa-arrow-left"></i>
      </button>
      <h1 class="mb-8 text-3xl font-bold">Clientes</h1>
      <div>
        <a target="_blank" :href="`/clientsReports/excel`" class="p-2 rounded bg-green-500 mr-2">
          <i class="fa fa-file text-white" />
        </a>
        <a target="_blank" :href="`/clientsReports/pdf`" class="p-2 rounded bg-red-800">
          <i class="fa fa-print text-white" />
        </a>
      </div>
    </div>
    <div class="flex items-center justify-between mb-6">
      <search-filter v-model="form.search" class="mr-4 w-full max-w-md" @reset="reset">
      </search-filter>
      <Link class="btn-indigo" href="/clients/create">
        <span>Crear</span>
        <span class="hidden md:inline">&nbsp;Cliente</span>
      </Link>
    </div>
    <div class="bg-white rounded-md shadow overflow-x-auto">
      <table class="w-full whitespace-nowrap">
        <tr class="text-left font-bold">
          <th class="pb-4 pt-6 px-6">Nombre</th>
          <th class="pb-4 pt-6 px-6">Alias o Cédula</th>
          <th class="pb-4 pt-6 px-6">Pedidos</th>
          <th class="pb-4 pt-6 px-6">Correo electronico</th>
          <th class="pb-4 pt-6 px-6">Tipo de cliente</th>
          <th class="pb-4 pt-6 px-6">Empresa</th>
          <th class="pb-4 pt-6 px-6">Dirección</th>
          <th class="pb-4 pt-6 px-6">Adeudado</th>
          <th class="pb-4 pt-6 px-6" colspan="2">Telefono</th>
        </tr>
        <tr v-for="client in clients.data" :key="client.id" :class="`hover:bg-gray-100 focus-within:bg-gray-100 ${client.balance > 0 ? 'bg-red-300' : null}`">
          <td class="border-t">
            <Link class="flex items-center px-6 py-4 focus:text-indigo-500" :href="`/clients/${client.id}/edit`">
              {{ client.name }}
              <icon v-if="client.deleted_at" name="trash" class="flex-shrink-0 ml-2 w-3 h-3 fill-gray-400" />
            </Link>
          </td>
          <td class="border-t">
            <Link class="flex items-center px-6 py-4" :href="`/clients/${client.id}/edit`" tabindex="-1">
              {{ client.identification }}
            </Link>
          </td>
          <td class="border-t">
            <Link v-for="order in client.orders" :key="order.id" class="flex flex-row items-center px-6 py-4" :href="`/orders/${order.id}`" tabindex="-1">
              {{ order.number_order }}
            </Link>
          </td>
          <td class="border-t">
            <Link class="flex items-center px-6 py-4" :href="`/clients/${client.id}/edit`" tabindex="-1">
              {{ client.email }}
            </Link>
          </td>
          <td class="border-t">
            <Link class="flex items-center px-6 py-4" :href="`/clients/${client.id}/edit`" tabindex="-1">
              {{ client.typeClient }}
            </Link>
          </td>
          <td class="border-t">
            <Link class="flex items-center px-6 py-4" :href="`/clients/${client.id}/edit`" tabindex="-1">
              {{ client.companyName }}
            </Link>
          </td>
          <td class="border-t">
            <Link class="flex items-center px-6 py-4" :href="`/clients/${client.id}/edit`" tabindex="-1">
              {{ client.address }}
            </Link>
          </td>
          <td class="border-t">
            <Link class="flex items-center px-6 py-4" :href="`/clients/${client.id}/edit`" tabindex="-1">
              {{ client.balance }}$
            </Link>
          </td>
          <td class="border-t">
            <Link class="flex items-center px-6 py-4" :href="`/clients/${client.id}/edit`" tabindex="-1">
              {{ client.phone }}
            </Link>
          </td>
          <td class="w-px border-t">
            <Link class="flex items-center px-4" :href="`/clients/${client.id}/edit`" tabindex="-1">
              <icon name="cheveron-right" class="block w-6 h-6 fill-gray-400" />
            </Link>
          </td>
        </tr>
        <tr v-if="clients.data.length === 0">
          <td class="px-6 py-4 border-t" colspan="4">No hay clientes.</td>
        </tr>
      </table>
    </div>
    <pagination class="mt-6" :links="clients.links" />
  </div>
</template>

<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
import Icon from '@/Shared/Icon'
import pickBy from 'lodash/pickBy'
import Layout from '@/Shared/Layout'
import throttle from 'lodash/throttle'
import mapValues from 'lodash/mapValues'
import Pagination from '@/Shared/Pagination'
import SearchFilter from '@/Shared/SearchFilter'

export default {
  components: {
    Head,
    Icon,
    Link,
    Pagination,
    SearchFilter,
  },
  layout: Layout,
  props: {
    filters: Object,
    clients: Object,
  },
  data() {
    return {
      form: {
        search: this.filters.search,
        trashed: this.filters.trashed,
      },
    }
  },
  watch: {
    form: {
      deep: true,
      handler: throttle(function () {
        this.$inertia.get('/clients', pickBy(this.form), { preserveState: true })
      }, 150),
    },
  },
  methods: {
    back() {
      window.history.back();
    },
    reset() {
      this.form = mapValues(this.form, () => null)
    },
  },
}
</script>
